@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Signika:wght@300..700&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text {
  background: linear-gradient(133.66deg, #f9b607 -42.56%, #e82c03 117.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.custom-scrollbar {
  overflow: auto;
  height: calc(100vh - 80px);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0;
}

.custom-scrollbar {
  scrollbar-width: none;
}

.custom-scrollbar {
  -ms-overflow-style: none;
}

.spesifik-input::before {
  content: "•";
  position: absolute;
  left: 0.5rem;
  color: #4A4D4F;
}
/* input[type="radio"] {
  appearance: none; 
  -webkit-appearance: none;
  -moz-appearance: none; 
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #B5B6B7; 
  transition: all 0.3s ease; 
} */
input[type="radio"]:checked {
  background-color: #F15A24;
  border-color: #F15A24; 
  accent-color: #F15A24 ;
  /* box-shadow: 0 0 0 4px rgba(241, 90, 36, 0.3);  */
}

body {
  margin: 0;
  background-color: #EDEDED !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
};